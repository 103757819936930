<template>
	<div class="settings-content opacity-page" v-if="loaded">
		<div class="header-section row-space-tbf">
			<div class="space-left"></div>
			<div class="content">
				<div class="title">{{ $t('subscription.title') }}</div>
			</div>
			<div class="space-right"></div>
		</div>

		<div class="alert-section row-space-tbf">
			<div class="space-left"></div>
			<div class="content">
				<div class="alert-tbf red" v-if="$auth.user().instance.status == 'unpaid'">
					<div class="icon"><img src="/build/images/warning-square.svg"></div>
					<div class="data">
						<div class="title">{{ $t('subscription.plan_expired') }}</div>
						<div class="description">{{ $t('subscription.plan_expired_description') }}</div>
					</div>
				</div>

				<div class="alert-tbf red" v-else-if="$auth.user().instance.cancel_at_period_end">
					<div class="icon"><img src="/build/images/warning-square.svg"></div>
					<div class="data">
						<div class="title">{{ $t('subscription.plan_cancel') }} {{ moment($auth.user().instance.cancel_at_period_end, 'DD/MM/YYYY').format('DD MMMM YYYY') }}</div>
						<div class="description">{{ $t('subscription.plan_cancel_description') }}</div>
					</div>
				</div>
			</div>
			<div class="space-right"></div>
		</div>

		<div class="list-subscriptions row-space-tbf">
			<div class="space-left"></div>
			<div class="content">
				<div class="row-item" v-for="packageItem in packagesList.filter(el => el.package_bundle == 'Single')">
					<div class="item-subscription" v-bind:class="{active: selectedPackage.package_id == packageItem.package_id}">
						<div class="header">
							<div class="title">{{ packageItem.package_name }}</div>
							<div class="selected-check"><icon-check /></div>
						</div>
						<div class="price-box">
							<div class="value"><span class="old-price" v-if="packageItem.old_price_in_euro != packageItem.price_in_euro">{{ packageItem.old_price_in_euro }}</span> {{ packageItem.price_in_euro }} <span class="prefix">EUR</span></div>
							<div class="period">/ {{ packageItem.interval == 'month' ? $t('general.monthly') : $t('general.yearly') }} + {{ $t('subscription.vat') }}</div>
						</div>
						<div class="list-features">
							<div class="item-feature" v-for="textFe in packageItem.includes">
								<div class="text">{{ textFe }}</div>
								<div class="icon-square"><icon-check /></div>
							</div>
						</div>

						<div class="action" v-if="['unpaid', 'new'].includes($auth.user().instance.status)">
							<button v-if="$auth.user().rights.can_view_subscription" class="btn-tbf" :id="`buttonUpgrade${packageItem.package_id}`" v-bind:class="[selectedPackage.package_id == packageItem.package_id ? 'white-blue' : 'blue' ]" @click="showModal('change_subscription')">
								<div class="loader"></div>
								<div class="text">{{ selectedPackage.package_id == packageItem.package_id ? $t('subscription.btn-renew') : $t('subscription.change') }}</div>
							</button>
						</div>

						<div class="action" v-else>
							<button class="btn-tbf" v-if="selectedPackage.package_id == packageItem.package_id && $auth.user().rights.can_view_subscription" v-bind:class="[$auth.user().instance.cancel_at_period_end ? 'white-blue' : 'white' ]" @click="$auth.user().instance.cancel_at_period_end ? showModal('renew_subscription') : showModal('cancel_subscription')">
								<span class="text">{{ $auth.user().instance.cancel_at_period_end ? $t('subscription.btn-renew') : $t('subscription.cancel') }}</span>
							</button>
							<button class="btn-tbf blue" :id="`buttonUpgrade${packageItem.package_id}`" @click="upgradeSubscription(packageItem)" v-else-if="$auth.user().rights.can_view_subscription">
								<div class="loader"></div>
								<div class="text">{{ $t('subscription.change') }}</div>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="space-right"></div>
		</div>

		<div class="header-with-tabs subscription row-space-tbf">
			<div class="space-left">
				<img v-if="$resize && $mq.above(780)" src="../../../assets/credit-card.svg">
			</div>
			<div class="content">
				<div v-if="$resize && $mq.above(600)" class="tabs">
					<div class="title-tab" @click="activeTab = 'list'" v-bind:class="{active: activeTab == 'list'}">{{ $t('subscription.payment_method') }}</div>
					<div class="title-tab" @click="activeTab = 'billing-details'" v-bind:class="{active: activeTab == 'billing-details'}">{{ $t('subscription.invoice-data') }}</div>
					<div class="title-tab" @click="activeTab = 'billings'" v-bind:class="{active: activeTab == 'billings'}">{{ $t('subscription.billings') }}</div>
				</div>
				<div v-else-if="$resize && $mq.below(599)" class="actions-dropdown">
					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text">{{activeTab == 'list' ? $t('subscription.payment_method') : activeTab == 'billing-details' ? $t('subscription.invoice-data') : $t('subscription.billings') }}</span>
							<div class="icon-filter"><icon-arrow /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">

							<template>
								<div class="dropdown-item" @click="activeTab = 'list'" v-bind:class="{active: activeTab == 'list'}">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t('subscription.payment_method') }}</span>
									</div>
								</div>
								<div class="dropdown-item" @click="activeTab = 'billing-details'" v-bind:class="{active: activeTab == 'billing-details'}">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t('subscription.invoice-data') }}</span>
									</div>
								</div>
								<div class="dropdown-item" @click="activeTab = 'billings'" v-bind:class="{active: activeTab == 'billings'}">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t('subscription.billings') }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>
				</div>

				<div class="actions mobile">
					<button v-if="$auth.user().rights.can_view_subscription" class="btn-tbf blue" @click="showEditCardDetails = true" v-show="activeTab == 'list' && showEditCardDetails == false">
						<div class="icon"><icon-edit /></div>
						<span v-if="$resize && $mq.above(600)" class="text">{{ $t('subscription.card-details') }}</span>
					</button>
					<button class="btn-tbf grey" @click="showEditCardDetails = false" v-show="activeTab == 'list' && showEditCardDetails == true">
						<div v-if="$resize && $mq.below(599)" class="icon"><icon-close /></div>
						<span class="text">{{ $resize && $mq.above(600) ? $t('general.cancel') : ''}}</span>
					</button>

					<button v-if="$auth.user().rights.can_view_subscription" class="btn-tbf grey" @click="editInstance()" v-show="activeTab == 'billing-details' && activeEdit == false">
						<div v-if="$resize && $mq.below(599)" class="icon"><icon-edit /></div>
						<span class="text">{{ $resize && $mq.above(600) ? $t('general.edit') : ''}}</span>
					</button>
					<button class="btn-tbf grey" @click="cancelInstance()" v-show="activeTab == 'billing-details' && activeEdit == true">
						<div v-if="$resize && $mq.below(599)" class="icon"><icon-close /></div>
						<span class="text">{{ $resize && $mq.above(600) ? $t('general.cancel') : ''}}</span>
					</button>
					<button class="btn-tbf grey" @click="showEditNotification = false, getEmailNotifications()" v-show="activeTab == 'billings' && showEditNotification == true">
						<div v-if="$resize && $mq.below(599)" class="icon"><icon-close /></div>
						<span class="text">{{ $resize && $mq.above(600) ? $t('general.cancel') : ''}}</span>
					</button>
				</div>
			</div>
			<div class="space-right"></div>
		</div>

		<div class="list-cards row-space-tbf" v-if="activeTab == 'list'">
			<div class="space-left"></div>
			<div class="content">

				<div class="add-item" v-if="showEditCardDetails">
					<div class="main-label">
						<div class="title"><span class="text">{{ $t('subscription.card-details') }}</span></div>
						<div class="description">{{ $t('subscription.edit-card-details-text') }}</div>
						<div class="icons-bank">
							<div class="icon-bank">
								<img src="../../../assets/payment-visa.svg">
							</div>
							<div class="icon-bank">
								<img src="../../../assets/payment-mastercard.svg">
							</div>
							<div class="icon-bank">
								<img src="../../../assets/payment-stripe.svg">
							</div>
						</div>
					</div>
					<div class="form-group-inline">
						<div class="form">
							<div class="input-group w-100" v-bind:class="{ has_error: $v.card.number.$error || cardErrors.cardNumber || stripeError != '' }">
								<div class="label-header">
									<label class="label">{{$t('card-details.number')}}</label>
									<div class="error-msg" v-if="cardErrors.cardNumber">{{ cardErrors.cardNumber }}</div>
									<div class="error-msg" v-else-if="stripeError != ''">{{ stripeError }}</div>
								</div>
								<div class="input-box">
									<div class="icon-left"><icon-card /></div>
									<div class="icon-right" v-if="card.number != ''" @click.stop="card.number = ''"><icon-close class="icon-clear"/></div>
									<input type="tel" v-model="card.number" class="input-text" :placeholder="'XXXX XXXX XXXX ' + cardDetails.last4" v-cardformat:formatCardNumber maxlength="19" ref="first_input">
								</div>
							</div>
							<div class="input-group w-50" v-bind:class="{ has_error: $v.card.expiration.$error || cardErrors.cardExpiry }">
								<div class="label-header">
									<label class="label">{{$t('card-details.valability')}}</label>
								</div>
								<div class="input-box">
									<div class="icon-left"><icon-date /></div>
									<div class="icon-right" v-if="card.expiration != ''" @click.stop="card.expiration = ''"><icon-close class="icon-clear"/></div>
									<input type="tel" v-model="card.expiration" class="input-text simple-input" :placeholder="cardDetails.exp_month + ' / ' + cardDetails.exp_year" v-cardformat:formatCardExpiry maxlength="9">
								</div>
							</div>
							<div class="input-group w-50" v-bind:class="{ has_error: $v.card.cvc.$error || cardErrors.cardCvc }">
								<div class="label-header">
									<label class="label">{{$t('card-details.cvc')}}</label>
								</div>
								<div class="input-box">
									<div class="icon-left"><icon-password /></div>
									<div class="icon-right" v-if="card.cvc != ''" @click.stop="card.cvc = ''"><icon-close class="icon-clear"/></div>
									<input type="tel" v-model="card.cvc" class="input-text simple-input" placeholder="***" v-cardformat:formatCardCVC maxlength="4">
								</div>
							</div>

							<div class="submit-btn">
								<div class="btn-box">
									<button class="btn-tbf white center" @click="showEditCardDetails = false">
										<div class="text">{{ $t('general.cancel') }}</div>
									</button>
								</div>
								<div class="btn-box">
									<button id="buttonChangeCardDetails" class="btn-tbf blue center" @click="changeCardDetails">
										<div class="loader"></div>
										<span class="text">{{$t('card-details.modify')}}</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="item-card active" v-if="cardDetails && showEditCardDetails == false">
					<div class="normal-view">
						<div v-if="$resize && $mq.above(600)" class="checkbox"><icon-check /></div>
						<div v-if="$resize && $mq.above(600)" class="logo-bank">
							<img :src="`/build/images/${cardDetails.brand}.png`">
						</div>
						<div class="title-card">{{ $t('subscription.text_detail_card', {brand: cardDetails.brand, last4: cardDetails.last4}) }}</div>
						<div class="expiration" v-bind:class="{expired: cardDetails.expired}">
							{{ cardDetails.expired ? $t('subscription.expired') : $t('subscription.card_expiring', {month: cardDetails.exp_month, year: cardDetails.exp_year}) }}
						</div>
					</div>
				</div>
			</div>
			<div class="space-right"></div>
		</div>

		<div class="form-billing-details row-space-tbf" v-if="activeTab == 'billing-details'">
			<div class="space-left"></div>
			<div class="content form-group">
				<div class="data-group">
					<div class="title">{{ $t('subscription.invoice-data') }}</div>
					<div class="description">{{ $t('subscription.invoice-data-text') }}</div>
				</div>
				<div class="form">
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('subscription.type_account') }}*</label>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-people /></div>
							<multiselect 
								:disabled="activeEdit == false"
								:allow-empty="false"
								:preserve-search="true"
								v-model="selectedCompanie"
								class="select-tags-tbf"
								:options="optionsCompanies" 
								:multiple="false" 
								:close-on-select="true"
								:placeholder="$t('invoice-details.account-type')" 
								track-by="value" 
								label="name"
								:show-labels="false"
								@input="selectedCompanie.value == 'company' ? user.has_company = 1 : user.has_company = 0, $v.$reset()">
								<template slot="noResult">{{ $t('invoice-details.no-results') }}</template>
							</multiselect>
						</div>
					</div>
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('subscription.country') }}*</label>
							<div v-if="errorsBeInstance.country" class="error-msg">{{ errorsBeInstance.country.join(" | ") }}</div>
						</div>
						<div class="input-box">
							<div class="icon-left"><icon-globe /></div>
							<multiselect
								:disabled="activeEdit == false"
								v-model="selectedCountry"
								class="select-tags-tbf"
								:options="optionsCountries"
								:allow-empty="false"
								:show-labels="false"
								:placeholder="$t('subscription.choose_country')" 
								track-by="id" 
								label="name">
								<template slot="singleLabel" slot-scope="props">
									<div class="option_ellipsis">
										{{ props.option.name }}
									</div>
								</template>
								<template slot="option" slot-scope="props">
									<div class="option_ellipsis">
										{{ props.option.name }}
									</div>
								</template>
								<template slot="noResult">{{ $t('invoice-details.no-results') }}</template>
							</multiselect>
						</div>
					</div>
					<div class="input-group w-100">
						<div class="label-header">
							<label v-if="user.has_company === 1" class="label">{{ $t('subscription.company') }}*</label>
							<label v-else class="label">{{ $t('subscription.complet_name') }}*</label>
							<div v-if="errorsBeInstance.company_name" class="error-msg">{{ errorsBeInstance.company_name.join(" | ") }}</div>
							<div v-if="errorsBeInstance.name" class="error-msg">{{ errorsBeInstance.name.join(" | ") }}</div>
						</div>
						<div class="input-box" v-bind:class="activeEdit == true ? {has_error: $v.user.name.$error, complete: user.name != '' && !$v.user.name.$error} : ''">
							<div class="icon-left"><icon-people /></div>
							<div class="icon-right" v-if="user.name != '' && activeEdit == true" @click.stop="user.name = ''"><icon-close class="icon-clear" /></div>
							<input :disabled="activeEdit == false" type="text" :placeholder="$t('general.name_placeholder')" class="input-text" v-model="user.name">
						</div>
					</div>
					<div v-if="user.has_company === 1" class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('subscription.cui') }}*</label>
							<div v-if="errorsBeInstance.cui" class="error-msg">{{ errorsBeInstance.cui.join(" | ") }}</div>
						</div>
						<div class="input-box" v-bind:class="activeEdit == true ? {has_error: $v.user.cui.$error, complete: user.cui != '' && !$v.user.cui.$error} : ''">
							<div class="icon-left"><icon-cui /></div>
							<div class="icon-right" v-if="user.cui != '' && user.cui && activeEdit == true" @click.stop="user.cui = ''"><icon-close class="icon-clear" /></div>
							<input :disabled="activeEdit == false" type="text" :placeholder="$t('subscription.cui_placeholder')" class="input-text" v-model="user.cui">
						</div>
					</div>
					<div v-else class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('subscription.cnp') }}*</label>
							<div v-if="errorsBeInstance.cnp" class="error-msg">{{ errorsBeInstance.cnp.join(" | ") }}</div>
						</div>
						<div class="input-box" v-bind:class="activeEdit == true ? {has_error: $v.user.cnp.$error, complete: user.cnp != '' && !$v.user.cnp.$error} : ''">
							<div class="icon-left"><icon-cnp /></div>
							<div class="icon-right" v-if="user.cnp != '' && user.cnp && activeEdit == true" @click.stop="user.cnp = ''"><icon-close class="icon-clear" /></div>
							<input :disabled="activeEdit == false" type="text" :placeholder="$t('subscription.cnp_placeholder')" class="input-text" v-model="user.cnp">
						</div>
					</div>
					<div class="input-group w-100" v-show="activeEdit == true" style="position: relative">
						<div class="error-msg-settings" v-show="error_message != ''">{{error_message}}</div>
						<button id="buttonUpdate" class="btn-tbf blue btn-submit" @click="saveChanges()">
							<div class="loader"></div>
							<span class="text">{{$t('general.update')}}</span>
						</button>
					</div>
				</div>
			</div>
			<div class="space-right"></div>
		</div>

		<div class="list-cards bill row-space-tbf" v-if="activeTab == 'billings'">
			<div class="space-left"></div>
			<div class="content">
				<div class="add-item">
					<div class="main-label">
						<div class="title"><span class="text">{{ $t('subscription.invoice_title') }}</span></div>
						<div class="description">{{ $t('subscription.invoice_description') }}</div>
					</div>
					<div class="form-group-inline">
						<div v-if="email_error" class="email_error">{{$t('subscription.invalid_email')}}</div>
						<div class="form" v-if="showEditNotification">
							<div class="input-group w-100 mb-0">
								<div class="emails-invoices">
									<div class="main-input">
										<div class="add-email">
											<div class="icon-right" v-if="inputEmail != '' && inputEmail && showEditNotification == true" @click.stop="clearEmailInput"><icon-close class="icon-clear" /></div>
											<input :disabled="keyEnter" type="email" :placeholder="$t('subscription.placeholder_emails')" class="input-text email-invoices" v-model="inputEmail" @keydown.enter="saveEmailsNotifications(inputEmail, true)">
											<div class="btn-box">
												<button v-if="inputEmail" id="buttonAddEmails" class="btn-tbf blue btn-save" @click="saveEmailsNotifications(inputEmail, false)">
													<div class="loader"></div>
													<span class="text">{{$t('subscription.save_emails')}}</span>
												</button>
											</div>
										</div>
									</div>

									<div class="textarea-selected-emails" v-if="savedEmails.length > 0">
										<span v-for="email, index in savedEmails" class="name">
											{{ email }}
											<button class="remove" @click="deleteEmail(index)"><icon-close /></button>
										</span>
									</div>
								</div>
							</div>
						</div>
						<div class="form" v-else>
							<div class="input-group w-100">
								<div class="emails-invoices" :class="{show: !showEditNotification}">
									<span  v-if="savedEmails.length" class="name">{{savedEmails.map((el) => { return el}).join(", ")}}</span>
									<span class="empty-emails" v-else>{{ $t('subscription.empty_emails_invoice') }}</span>
									<div v-if="$auth.user().rights.can_view_subscription" @click="showEditNotification = true" class="edit-invoice"><icon-edit /></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="$auth.user().rights.can_view_subscription" v-for="(invoice, index) in invoices" :key="'invoice-' + index" class="item-card active">
					<div class="normal-view">
						<div v-if="$resize && $mq.above(600)" class="invoice"><icon-invoice /></div>
						<div class="title-card"> {{moment(invoice.created).format('DD MMM YYYY')}} - {{$t('subscription.invoice') + ' ' + invoice.invoice_number}}</div>
						<div class="expiration">{{invoice.amount_paid + ' ' + invoice.currency}}</div>
						<div @click="downloadInvoice(invoice.download_link)" class="download-invoice"><icon-download /></div>
					</div>
				</div>
			</div>
			<div class="space-right"></div>
		</div>
	</div>
	<loader-subscription v-else />
</template>


<script>
	import IconClose from '../../Icons/Close.vue'
	import IconCheck from '../../Icons/Check.vue'
	import IconEdit from '../../Icons/Edit.vue'
	import IconPeople from '../../Icons/People.vue'
	import IconCnp from '../../Icons/Cnp.vue'
	import IconGlobe from '../../Icons/Globe.vue'
	import IconCui from '../../Icons/Cui.vue'
	import IconCard from '../../Icons/Card'
	import IconDate from '../../Icons/Date'
	import IconPassword from '../../Icons/Password'
	import IconArrow from '../../Icons/Arrow'
	import IconInvoice from '../../Icons/Invoice'
	import IconDownload from '../../Icons/Download'
	import LoaderSubscription from '../../PagesLoaders/Subscription'
	import { required, minLength, requiredIf, sameAs } from 'vuelidate/lib/validators'

	export default {
		components: {
			IconClose,
			IconCheck,
			IconEdit,
			IconPeople,
			IconCnp,
			IconGlobe,
			IconCui,
			IconCard,
			IconDate,
			IconPassword,
			IconArrow,
			IconInvoice,
			IconDownload,
			LoaderSubscription
		},
		data() {
			return {
				loaded: false,
				name: '',
				showEditCardDetails: false,
				showEditNotification: false,
				activeTab: 'list',
				activeEdit: false,
				user: {
					name: '',
					cui: '',
					cnp: '',
					country: '',
					has_company: '',
					cui_valid: false
				},
				selectedCountry: {},
				optionsCountries: [],
				selectedCompanie: {},
				optionsCompanies: [{value: "individual", name: this.$t('subscription.individual')}, {value: "company", name: this.$t('subscription.company_title')}],
				packagesList: [],
				selectedPackage: {package_id: 0},
				cardDetails: '',
				card:{
					number: '',
					expiration: '',
					cvc: ''
				},
				cardErrors: {},
				stripeError: '',
				error_message: '',
				invoices: [],
				inputEmail: '',
				savedEmails: [],
				storeEmails: [],
				email_error: false,
				keyEnter: false,
				errorsBeInstance: {}
			}
		},
		validations: {
			user: {
				name: {required},
				cui: {
					required: requiredIf(function () {
						return this.user.has_company === 1
					})
				},
				cnp: {
					required: requiredIf(function () {
						return this.user.has_company === 0
					})
				}
			},
			card: {
				number: {required: requiredIf(function () {
					return this.activeTab == 'list' && this.showEditCardDetails
				})},
				expiration: {required: requiredIf(function () {
					return this.activeTab == 'list' && this.showEditCardDetails
				})},
				cvc: {required: requiredIf(function () {
					return this.activeTab == 'list' && this.showEditCardDetails
				})}
			}
		},
		async mounted(){
			if(this.$auth.user().is_entrepreneur && !this.$auth.user().free_account){
				var title = this.$t('settings-navbar.subscriptions');
				this.$root.$emit("navbar_title", title);

				this.getUserData()
				await this.getCountries()
				await this.getCardDetails()
				await this.getEmailNotifications()
				await this.getInvoices()
				await this.getPackages()
			}else{
				this.$router.push({name: 'forbbiden'})        		
			}

			this.$root.$on('changePlanAccount', () => {
				this.$auth.fetch().then(()=> { this.getPackages() })
			})

			this.loaded = true
		},
		methods: {
			getUserData(){
				this.$auth.load().then(() => {
					this.user.name = this.$auth.user().instance.has_company === 1 ? this.$auth.user().instance.company_name : this.$auth.user().instance.name
					this.user.cnp = this.$auth.user().instance.cnp
					this.user.cui = this.$auth.user().instance.cui
					this.user.has_company = this.$auth.user().instance.has_company

					this.selectedCompanie = this.$auth.user().instance.has_company === 1 ? {value: "company", name: this.$t('subscription.company_title')} : {value: "individual", name: this.$t('subscription.individual')}
				})
			},
			async getCardDetails(){
				await axios.get('card-details')
				.then(({data}) => {
					this.cardDetails = data
					var dateExpired = moment(`${data.exp_month}-${data.exp_year}`, 'MM/YYYY').endOf('month')
					var currentDate = moment().endOf('month')
					this.cardDetails.expired = currentDate > dateExpired
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			async getInvoices() {
				await axios.get('list-invoices')
				.then(({data}) => {
					this.invoices = data;
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			async getEmailNotifications() {
				await axios.get('instance-notifications')
					.then(({data}) => {
						this.savedEmails = data.data;
						this.storeEmails = [...this.savedEmails]
					}).catch(error => {
						if(error.response) {
							if(error.response.status == 500) {
								alert(this.$t('error.500'))
							}
						}
					})
			},
			downloadInvoice(link) {
				window.location.href = link;
			},
			saveEmailsNotifications(newEmail, bool){
				this.keyEnter = bool
				var buttonName = 'buttonAddEmails'
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				
				var regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

				if(regexEmail.test(String(newEmail).toLowerCase())) {
					this.email_error = false
					$('.emails-invoices').removeClass('has_error')

					this.storeEmails.push(newEmail.toLowerCase())
					var objData = { email: this.storeEmails.join(',') }
					this.saveEmails(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
				} else {
					this.email_error = true
					$('.emails-invoices').addClass('has_error')
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.email-error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('subscription.save_emails')
							btnSubmit.disabled = false
							this.keyEnter = false
						}, 1000)
					}, 300)
				}
			},
			deleteEmail(index){
				this.storeEmails.splice(index,1);
				var objData = { email: this.storeEmails.join(',') }
				axios.patch('/instance-notifications', objData)
				.then(() => {this.getEmailNotifications();})
				.catch(error => {
					this.storeEmails = [...this.savedEmails]
					if(error.response.status == 500){
						alert(this.$t('error.500'))
					}
				})
			},
			saveEmails(data, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch('/instance-notifications', data)
				.then(() => {
					btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
							btnSubmit.classList.add('completed')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								btnSubmit.classList.remove('completed')
								btnSubmitText.innerHTML = this.$t('subscription.save_emails')
								this.inputEmail = ''
								this.keyEnter = false
								this.getEmailNotifications();
							}, 1000)
						}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.email-error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('subscription.save_emails')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								alert(this.$t('error.500'))
							}
						}, 1000)
					}, 300)
				})
			},
			clearEmailInput(){
				this.inputEmail = '';
				this.email_error = false;
				$('.emails-invoices').removeClass('has_error');
			},
			async getPackages(){
				await axios.get('packages')
				.then(({data}) => {
					this.packagesList = data.data;
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					if(this.$auth.user().instance){
						if(this.$auth.user().instance.package_id && this.packagesList.find(el => el.package_id == this.$auth.user().instance.package_id)){
							let currentPackage = this.packagesList.find(el => el.package_id == this.$auth.user().instance.package_id)
							let newPackage = {}

							switch(this.$auth.user().instance.package_id){
								case 4:
									newPackage = this.packagesList.find(el => el.package_id == 9)
									newPackage.price_in_euro = currentPackage.price_in_euro
									newPackage.price_in_ron = currentPackage.price_in_ron
									break;
								case 5:
									newPackage = this.packagesList.find(el => el.package_id == 10)
									newPackage.price_in_euro = currentPackage.price_in_euro
									newPackage.price_in_ron = currentPackage.price_in_ron
									break;
								case 13:
									newPackage = this.packagesList.find(el => el.package_id == 10)
									newPackage.price_in_euro = currentPackage.price_in_euro
									newPackage.price_in_ron = currentPackage.price_in_ron
									newPackage.old_price_in_euro = currentPackage.old_price_in_euro
									newPackage.old_price_in_ron = currentPackage.old_price_in_ron
									newPackage.interval = currentPackage.interval
									break;
								case 6:
									newPackage = this.packagesList.find(el => el.package_id == 12)
									newPackage.price_in_euro = currentPackage.price_in_euro
									newPackage.price_in_ron = currentPackage.price_in_ron
									break;
								default: 
									newPackage = currentPackage
							}

							this.selectedPackage = newPackage
						}else{
							this.selectedPackage = {package_id: 0}
						}
					}else{
						this.selectedPackage = {package_id: 0}
					}
					
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
			},
			async getCountries(){
				await axios.get('/countries').then(({data}) => {
					this.optionsCountries = Object.entries(data.data).map((el,index) =>{
						return{id: el[0], name:el[1]}
					})
					this.selectedCountry = this.optionsCountries.find(el => el.id == this.$auth.user().instance.country)
					this.user.country = this.selectedCountry.id
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			editInstance(){
				this.activeEdit = true;
			},
			cancelInstance() {
				this.activeEdit = false;
				this.getUserData();
			}, 
			saveChanges() {
				var buttonName = 'buttonUpdate'
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

				this.$v.$touch();
				if(!this.$v.$invalid){
					if(this.selectedCompanie.value == 'company'){
						this.checkVatNumber(btnSubmit, btnSubmitText, btnSubmitLoader);
					}else{
						this.updateDetails(btnSubmit, btnSubmitText, btnSubmitLoader);
					}
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			updateDetails(btnSubmit, btnSubmitText, btnSubmitLoader) {
				var objData = { country: this.selectedCountry.id }

				if(this.selectedCompanie.value == 'company'){
					objData.company_name = this.user.name
					objData.cui = this.user.formatedCui
					objData.has_company = 1
				}else{
					objData.name = this.user.name
					objData.cnp = this.user.cnp
					objData.has_company = 0
				}
				
				axios.post('/edit-instance', objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						setTimeout(()=>{
							this.activeEdit = false
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.update')
							this.$auth.fetch().then(() => { this.getUserData() })
						}, 1000)
					}, 300)
				})
				.catch(error => {
					this.errorsBeInstance = error.response.data.errors
					btnSubmitText.innerHTML = this.$t('btn-submit.error')
					btnSubmit.classList.add('error')
					btnSubmitLoader.classList.remove('onProgress', 'finish')
					btnSubmit.classList.remove('loading')
					setTimeout(()=>{
						btnSubmit.classList.remove('error')
						btnSubmitText.innerHTML = this.$t('general.update')
						btnSubmit.disabled = false
						if(error.response.status == 500){
							this.error_message = this.$t('error.500')
						}
					}, 1000)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
			showModal(type){
				this.$root.$emit('open_modal', type)
			},
			upgradeSubscription(packageData){
				this.$root.$emit('open_modal', 'change_plan_confirmation', { packageData : packageData})
			},
			changeCardDetails(){
				var buttonName = `buttonChangeCardDetails`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				this.$v.$touch()
				this.cardErrors = {}
				this.stripeError = ''
				var cardError = false

				// validate card number
				if(!this.$cardFormat.validateCardNumber(this.card.number)){
					this.cardErrors.cardNumber = this.$t('validator.invalid_credit_card');
					cardError = true
				};

				// validate card expiry
				if (!this.$cardFormat.validateCardExpiry(this.card.expiration)) {
					this.cardErrors.cardExpiry = this.$t('validator.invalid_credit_card_date');
					cardError = true
				};

				// validate card CVC
				if (!this.$cardFormat.validateCardCVC(this.card.cvc)) {
					this.cardErrors.cardCvc = this.$t('validator.invalid_credit_card_cvc');
					cardError = true
				};

				if(this.$v.$invalid || cardError){
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('card-details.modify')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}else{
					axios.post('change-card', {
						number: this.card.number,
						exp_month: this.card.expiration.substring(0, 2),
						exp_year: this.card.expiration.substring(5, 9),
						cvc: this.card.cvc
					})
					.then((data) => {
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
							btnSubmit.classList.add('completed')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								btnSubmit.classList.remove('completed')
								btnSubmitText.innerHTML = this.$t('card-details.modify')
								this.getCardDetails().then(() => {
									this.$v.$reset()
									this.showEditCardDetails = false
									this.card = { number: '', expiration: '', cvc: ''}
								})
							}, 1000)
						}, 300)
					})
					.catch(error => {
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('subscription.coupon.apply-error')
							btnSubmit.classList.add('error')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								btnSubmit.classList.remove('error')
								btnSubmitText.innerHTML = this.$t('card-details.modify')
								this.stripeError = error.response.data.message
								if(error.response) {
									if(error.response.status == 500) {
										alert(this.$t('error.500'))
									}
								}
							}, 1000)
						}, 300)
					})
					.finally(() => {
						setTimeout(()=>{
							btnSubmit.disabled = false
						}, 1500)
					})
				}
			},
			checkVatNumber(btnSubmit, btnSubmitLoader, btnSubmitText){
				var cui_number = this.user.cui.replace(/[^0-9]/g,'')
				var cui_country = this.user.cui.replace(/[^a-zA-Z]+/g, '').toUpperCase()

				if(!cui_country.length){
					cui_country = 'RO'
				}

				this.user.formatedCui = `${cui_country}${cui_number}`
				setTimeout(() => this.updateDetails(btnSubmit, btnSubmitLoader, btnSubmitText), 100)
			}
		}
	}
</script>

<style lang="scss">
	#buttonAddEmails{
		border-radius: 5px;
		.text{
			font-size: 10px;
			line-height: 1;
		}
	}
	.email-invoices{
		&:disabled{
			background: #FFF;
			color: #181B30;
		}
	}
	.email_error{
		color: #fb5454;
    	font-size: 10px;
		margin-bottom: 10px;
		position: absolute;
   		top: 10px;
	}
	.header-with-tabs.subscription{
		.content{
			height: 101px;
		}
	}
	.emails-invoices{
		border-radius: 12px;
		background: #FFF;
		padding: 15px;
		border: 1px solid $borderCard;
		font-size: 14px;
		&.show {
			display: flex;
		}
		&.has_error{
			border-color: $red;
		}
		.edit-invoice{
			display: flex;
			align-items: center;
			margin-left: 10px;
			width: 20px;
			cursor: pointer;
		}
		.empty-emails,
		.name {
			display: flex;
			flex: 1;
			display: inline-block;
		}
		.main-input{
			.add-email{
				display: flex;
			}
			.icon-right{
				position: absolute;
				right: 150px;
				top: 50px;
				cursor: pointer;
			}
			.input-text{
				height: 25px;
				width: 100%;
    			border: none;
				outline: none;
				padding-right: 35px;
			}
			button{
				height: 25px;
				min-width: 90px !important;
				font-size: 12px
			}
		}
		.textarea-selected-emails{
			margin-top: 15px;
			.name{
				font-size: 12px;
				background-color: #FFF;
				border: 1px solid #dcdee1;
				display: inline-flex;
				align-items: center;
				border-radius: 5px;
				padding: 5px 10px;
				margin-right: 10px;
				margin-bottom: 8px;
				.remove{
					background: transparent;
					border: 0;
					padding: 0;
					display: flex;
					margin-left: 10px;
					cursor: pointer;
					&:focus{
						outline: none;
					}
					svg{
						height: 8px;
						width: auto;
					}
				}
			}
		}
	}
</style>